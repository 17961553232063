import React from 'react';
import {
  isAuthenticatedRequest,
  haveTwoHoursElapsedSinceLastRequest,
  setVisitCookie,
  invalidateRequest,
} from 'lib/auth';

import isProduction from 'lib/is-production';
import { setAdapter } from 'actions/user';
import { initialiseAdapter } from 'lib/adapter';

export default (App) =>
  class WithParticipantAccess extends React.Component {
    static async getInitialProps(ctx) {
      // On the client-side, `ctx.req` isn't defined.
      if (typeof window !== 'object') {
        const host =
          isProduction || !process.env.NEXT_PUBLIC_DEV_API_ENDPOINT
            ? `https://${ctx.req.headers.host}`
            : process.env.NEXT_PUBLIC_DEV_API_ENDPOINT;

        if (
          isAuthenticatedRequest(ctx.req) &&
          ctx.req.cookies.hasOwnProperty('unexpected_visit') &&
          !haveTwoHoursElapsedSinceLastRequest(ctx.req)
        ) {
          await ctx.reduxStore.dispatch(
            setAdapter({
              token: ctx.req.cookies.unexpected_thunder,
              host,
            }),
          );

          initialiseAdapter(ctx)
        } else {
          try {
            // clear all session variables from localStorage
            invalidateRequest(ctx.res);

            // fetch anonymous user token
            const tokenResponse = await fetch(`${host}/token`, {
              credentials: 'same-origin',
            });

            const data = await tokenResponse.json();
            const token = data?.data?.attributes?.token;

            ctx.token = token;

            initialiseAdapter(ctx)

            await ctx.reduxStore.dispatch(
              setAdapter({
                token,
                host,
              }),
            );

            // set new visit cookie
            setVisitCookie(ctx.res);
          } catch (e) {
            console.log('Exception: Failed to fetch token', e);
          }
        }
      }

      let appProps = {};

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx);
      }

      return {
        ...appProps,
      };
    }

    render() {
      return <App {...this.props} />;
    }
  };
