import { CSPConfig } from 'types/csp';
import isBoolean from 'lodash/isBoolean';
import { supportsCSP3 } from './supports-csp3';

export const baseCSP: CSPConfig = {
  'default-src': ["'self'"],
  'script-src': [
    "'self'",
    'https://www.googletagmanager.com',
    'https://www.google.com/recaptcha/api.js',
    'https://www.gstatic.com',
    'https://www.google-analytics.com/analytics.js',
    'https://analytics.google.com',
    'http://cdn.segment.com',
    'https://widget.intercom.io',
    'https://js.intercomcdn.com',
    'https://cdn.jsdelivr.net',
    'https://cdnjs.cloudflare.com',
    'https://ajax.googleapis.com',
    'https://www.browsealoud.com',
    'https://plus.browsealoud.com',
    'https://maxcdn.bootstrapcdn.com',
    'https://ssl.google-analytics.com',
    'https://api.mapbox.com',
    'https://assets.zendesk.com',
    'https://cdn.mxpnl.com',
    'https://cdn.ravenjs.com',
    'https://f1-oc.readspeaker.com',
    'https://cdn1.readspeaker.com',
    'https://epsg.io',
    'https://analytics.engagementhq.com',
    'https://platform.twitter.com',
    'https://cdn.syndication.twimg.com',
    'https://log.pinterest.com',
    'https://assets.pinterest.com',
    'https://connect.facebook.net',
    'https://translate.google.com',
    'https://s.ytimg.com',
    'https://www.youtube.com/iframe_api',
    'https://apis.google.com',
    'https://translate.googleapis.com',
    'https://cdn.auth0.com',
  ],
  'worker-src': ['blob:'],
  'style-src': ["'self'", "'unsafe-inline'", 'https://fonts.googleapis.com'],
  'img-src': ["'self'", 'blob:', 'data:', 'https:'],
  'font-src': [
    "'self'",
    'data:',
    'https://fonts.gstatic.com',
    'https://unpkg.com/boxicons@2.0.7/fonts/',
    'https://cdnjs.cloudflare.com',
    'https://use.typekit.net',
  ],
  'object-src': ['data:'],
  'base-uri': ["'self'"],
  'form-action': ["'self'"],
  'frame-ancestors': ["'self'"],
  'connect-src': [
    "'self'",
    'https://cdn.segment.com',
    'https://www.google-analytics.com',
    'https://www.google.co.in',
    'https://analytics.google.com',
    'https://o62215.ingest.sentry.io',
    'https://stats.g.doubleclick.net',
    'https://bam.nr-data.net',
    'https://plus.browsealoud.com',
    'https://syndication.twitter.com/settings',
    'https://z-m-graph.facebook.com',
    'https://graph.facebook.com',
    'https://stats.engagementhq.com',
    'https://views.unsplash.com',
    'https://translate.googleapis.com',
  ],
  'frame-src': [
    "'self'",
    'https://www.google.com',
    'https://recaptcha.google.com/recaptcha',
    'https://td.doubleclick.net',
    'https://www.youtube.com',
    'https://player.vimeo.com',
    'https://staticxx.facebook.com',
    'https://www.facebook.com',
    'https://web.facebook.com',
    'https://www.google.com.au',
    'https://platform.twitter.com',
    'https://syndication.twitter.com',
    'https://accounts.google.com',
    'https://abalancingact.com',
    'https://konveio.com',
    'https://arcgis.com',
    'https://drive.google.com/',
  ],
  'media-src': ["'self'", 'https:'],
  'child-src': ["'none'"],
  'upgrade-insecure-requests': true,
};

export const cspEnv = {
  'au-staging-vikings': `${process.env.NEXT_PUBLIC_IMGIX_NEW_STAGING_URL}`,
  'au-staging-vangaurds': `${process.env.NEXT_PUBLIC_IMGIX_NEW_STAGING_URL}`,
  'au-staging-titans': `${process.env.NEXT_PUBLIC_IMGIX_NEW_STAGING_URL}`,
  'au-staging-qa': `${process.env.NEXT_PUBLIC_IMGIX_NEW_STAGING_URL}`,
  'au-preprod': `${process.env.NEXT_PUBLIC_IMGIX_PRE_PROD_URL}`,
  'au-production': `${process.env.NEXT_PUBLIC_IMGIX_AUSTRALIA_URL}`,
  'us-production': `${process.env.NEXT_PUBLIC_IMGIX_CALIFORNIA_URL}`,
  'ca-production': `${process.env.NEXT_PUBLIC_IMGIX_CANADA_URL}`,
  'eu-production': `${process.env.NEXT_PUBLIC_IMGIX_EUROPE_URL}`,
  'uk-production': `${process.env.NEXT_PUBLIC_IMGIX_NEW_EUROPE_URL}`,
};

export const awsS3 = {
  'au-staging-vikings': 'https://s3-ap-southeast-2.amazonaws.com/ehq-staging/',
  'au-staging-vangaurds':
    'https://s3-ap-southeast-2.amazonaws.com/ehq-staging/',
  'au-staging-titans': 'https://s3-ap-southeast-2.amazonaws.com/ehq-staging/',
  'au-staging-qa': 'https://s3-ap-southeast-2.amazonaws.com/ehq-staging/',
  'au-preprod':
    'https://s3-ap-southeast-2.amazonaws.com/ehq-replica-australia/',
  'au-production':
    'https://s3-ap-southeast-2.amazonaws.com/ehq-production-australia/',
  'us-production':
    'https://s3-us-west-1.amazonaws.com/ehq-production-us-california/',
  'ca-production':
    'https://s3.ca-central-1.amazonaws.com/ehq-production-canada/',
  'eu-production': 'https://s3.eu-west-1.amazonaws.com/ehq-production-new-eu/',
  'uk-production': 'https://s3-eu-west-1.amazonaws.com/ehq-production-europe/',
};

function addUniqueSources(
  directive: string[],
  sourcesToAdd: string[],
): string[] {
  const sourceSet = new Set(directive);
  sourcesToAdd.forEach((source) => sourceSet.add(source));
  return Array.from(sourceSet);
}

export function getCSPPolicy({
  nonce,
  userAgent,
  isCSPReportOnly,
}: {
  nonce: string;
  userAgent: string;
  isCSPReportOnly: boolean;
}): string {
  let extendedCSP = { ...baseCSP };

  if (supportsCSP3(userAgent)) {
    // If the browser supports CSP3 features, use CSP3 policy
    extendedCSP['style-src-attr'] = addUniqueSources(
      extendedCSP['style-src-attr'],
      ["'unsafe-inline'"],
    );
    extendedCSP['style-src-elem'] = addUniqueSources(
      extendedCSP['style-src-elem'],
      [
        "'self'",
        "'unsafe-inline'",
        'https://fonts.googleapis.com',
        'https://fonts.gstatic.com',
        'https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css',
        'https://cdnjs.cloudflare.com',
        'https://www.gstatic.com',
      ],
    );
  } else {
    // If the browser does not support CSP3, use a more conservative CSP2 policy
    extendedCSP['style-src'] = addUniqueSources(extendedCSP['style-src'], [
      "'unsafe-inline'",
    ]); // Fallback for older browsers
  }

  extendedCSP['style-src'] = addUniqueSources(extendedCSP['style-src'], [
    `'nonce-${nonce}'`,
  ]);
  extendedCSP['script-src'] = addUniqueSources(extendedCSP['script-src'], [
    `'nonce-${nonce}'`,
  ]);

  if (process.env.ASSET_PREFIX) {
    extendedCSP['script-src'] = addUniqueSources(extendedCSP['script-src'], [
      process.env.ASSET_PREFIX,
    ]);
    extendedCSP['style-src'] = addUniqueSources(extendedCSP['style-src'], [
      process.env.ASSET_PREFIX,
    ]);
    extendedCSP['img-src'] = addUniqueSources(extendedCSP['img-src'], [
      process.env.ASSET_PREFIX,
    ]);
    extendedCSP['font-src'] = addUniqueSources(extendedCSP['font-src'], [
      process.env.ASSET_PREFIX,
    ]);
    extendedCSP['style-src-elem'] = addUniqueSources(
      extendedCSP['style-src-elem'],
      [process.env.ASSET_PREFIX],
    );
  }

  // check for imgix or S3 image
  if (process.env.CSP_ENV && cspEnv[process.env.CSP_ENV]) {
    extendedCSP['img-src'] = addUniqueSources(extendedCSP['img-src'], [
      cspEnv[process.env.CSP_ENV],
      awsS3[process.env.CSP_ENV],
    ]);
    extendedCSP['media-src'] = addUniqueSources(extendedCSP['media-src'], [
      awsS3[process.env.CSP_ENV],
    ]);
  }

  // Extend base CSP with region specific whitelist
  switch (process.env.NODE_ENV) {
    case 'development':
      extendedCSP['connect-src'] = addUniqueSources(
        extendedCSP['connect-src'],
        [process.env.DEV_API_ENDPOINT],
      );
      extendedCSP['script-src'] = addUniqueSources(extendedCSP['script-src'], [
        "'unsafe-eval'",
      ]);
      extendedCSP['upgrade-insecure-requests'] = false;
      break;

    case 'production':
      if (process.env.DEV_API_ENDPOINT) {
        extendedCSP['connect-src'] = addUniqueSources(
          extendedCSP['connect-src'],
          [process.env.DEV_API_ENDPOINT],
        );
      }
      break;

    default:
      break;
  }

  if (isCSPReportOnly) {
    delete extendedCSP['frame-ancestors'];
    delete extendedCSP['upgrade-insecure-requests'];
  }

  const cspString = Object.entries(extendedCSP)
    .map(([directive, sources]) =>
      isBoolean(sources) ? `${directive}` : `${directive} ${sources.join(' ')}`,
    )
    .join('; ');

  return cspString;
}
