export function supportsCSP3(userAgent: string) {
  // Modern browsers generally support CSP3 from these versions
  const csp3Browsers = [
    { name: 'Chrome', minVersion: 76 },
    { name: 'Firefox', minVersion: 69 },
    { name: 'Edge', minVersion: 79 },
    { name: 'Safari', minVersion: 13 }, // Safari began supporting CSP3 from version 13
  ];

  // Check if the browser supports CSP3 based on User-Agent and version
  for (const browser of csp3Browsers) {
    const regex = new RegExp(`${browser.name}/(\\d+)`);
    const match = userAgent.match(regex);
    if (match && parseInt(match[1], 10) >= browser.minVersion) {
      return true;
    }
  }

  // If no match, assume CSP3 isn't supported
  return false;
}
